.testimonial_google-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .testimonial_google-container .left {
    flex: 1 1 0;
    display: flex;
    flex-direction: row; }
    .testimonial_google-container .left .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      padding: 2rem; }
      .testimonial_google-container .left .content .previous-container, .testimonial_google-container .left .content .next-container {
        flex: 0 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 100; }
        .testimonial_google-container .left .content .previous-container button, .testimonial_google-container .left .content .next-container button {
          margin: 0 27px;
          height: 64px;
          width: 64px;
          background-color: #FFFFFF;
          box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid transparent;
          border-radius: 50%;
          outline: none; }
          .testimonial_google-container .left .content .previous-container button img, .testimonial_google-container .left .content .next-container button img {
            height: 26px;
            width: 17px; }
          .testimonial_google-container .left .content .previous-container button:hover, .testimonial_google-container .left .content .next-container button:hover {
            background-color: #e6e6e6; }
      .testimonial_google-container .left .content .card {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 0 54px 54px 54px;
        background-color: #ffffff;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.1);
        min-height: 300px;
        max-height: calc(388px - 80px);
        max-width: calc(427px - 80px);
        padding: 40px;
        z-index: 100; }
        .testimonial_google-container .left .content .card .header {
          flex: 0 1 100%;
          display: flex;
          flex-direction: row; }
          .testimonial_google-container .left .content .card .header .img {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: center; }
            .testimonial_google-container .left .content .card .header .img img {
              height: 100px;
              width: 100px;
              min-width: 100px;
              min-height: 100px;
              -o-object-fit: cover;
                 object-fit: cover;
              flex: 0 1 auto; }
          .testimonial_google-container .left .content .card .header .name, .testimonial_google-container .left .content .card .header .star-rating {
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            padding: 0 1rem; }
            .testimonial_google-container .left .content .card .header .name h3, .testimonial_google-container .left .content .card .header .name p, .testimonial_google-container .left .content .card .header .star-rating h3, .testimonial_google-container .left .content .card .header .star-rating p {
              margin: unset;
              padding: unset;
              flex: 0 1 100%; }
            .testimonial_google-container .left .content .card .header .name h3, .testimonial_google-container .left .content .card .header .star-rating h3 {
              color: #707070;
              font-size: 16px;
              font-weight: 900;
              letter-spacing: 0;
              line-height: 23px; }
            .testimonial_google-container .left .content .card .header .name .star, .testimonial_google-container .left .content .card .header .star-rating .star {
              height: 1.5rem;
              width: 1.5rem;
              flex: 0 1 auto; }
              .testimonial_google-container .left .content .card .header .name .star .fa-primary, .testimonial_google-container .left .content .card .header .star-rating .star .fa-primary {
                color: #F58123; }
        .testimonial_google-container .left .content .card .body {
          color: #707070;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px; }
        .testimonial_google-container .left .content .card .date {
          flex: 0 1 100%;
          display: flex;
          align-self: flex-end; }
          .testimonial_google-container .left .content .card .date p {
            flex: 0 1 auto;
            margin: unset;
            padding: unset;
            color: #AFAFAF;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 23px; }
      .testimonial_google-container .left .content .outline {
        position: absolute;
        top: 0;
        left: calc((50% - (370px/2) - 60px));
        box-sizing: border-box;
        height: 354px;
        width: 370px;
        border: 7px solid #CED7EC;
        border-radius: 0 88px 88px 88px;
        z-index: 10; }
      .testimonial_google-container .left .content .rectangle {
        position: absolute;
        top: 2rem;
        left: calc((50% - (340px / 2)) - 50px);
        height: 351px;
        width: 340px;
        background-color: #E7E7E7;
        z-index: 1;
        transform: skewX(20deg); }
  .testimonial_google-container .right {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .testimonial_google-container .right .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      max-width: 400px; }
      .testimonial_google-container .right .content h2 {
        flex: 1 1 auto;
        color: #5D5C5C;
        font-size: 45px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 54px;
        margin-bottom: 2rem; }
      .testimonial_google-container .right .content p {
        flex: 1 1 auto;
        color: #707070;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        text-align: right;
        margin-bottom: 2rem; }
      .testimonial_google-container .right .content .google-card {
        flex: 1 1 auto;
        max-width: 215px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 7px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.1);
        padding: 0.5rem 1rem; }
        .testimonial_google-container .right .content .google-card .img {
          flex: 0 1 auto; }
          .testimonial_google-container .right .content .google-card .img img {
            height: 37px;
            width: 37px;
            margin-right: 1rem; }
        .testimonial_google-container .right .content .google-card .rating {
          flex: 1 1 auto; }
          .testimonial_google-container .right .content .google-card .rating p {
            margin: unset;
            color: #6F6F6F;
            font-size: 17px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 40px; }

.testimonials-container {
  flex: 1 1 100%;
  background-color: whitesmoke;
  flex-direction: column;
  padding: 4rem 0; }
  .testimonials-container .testimonials {
    flex: 1;
    flex-direction: column; }
    .testimonials-container .testimonials .testimonial-header {
      align-items: center;
      align-content: center;
      flex-direction: column; }
    .testimonials-container .testimonials .testimonial-body {
      align-items: center;
      align-content: center;
      width: 100%;
      flex-direction: column; }
      .testimonials-container .testimonials .testimonial-body .buttons-container {
        margin: 1rem 0; }
        .testimonials-container .testimonials .testimonial-body .buttons-container input[type='radio'] {
          padding: 0.2em 0.7em;
          -webkit-appearance: none;
          outline: 0.1em solid gray;
          border-radius: 0;
          outline-offset: 0; }
        .testimonials-container .testimonials .testimonial-body .buttons-container input[type='radio']:checked {
          background-color: #5765cd; }
        .testimonials-container .testimonials .testimonial-body .buttons-container input[type='radio']:hover {
          cursor: pointer; }
    .testimonials-container .testimonials .quote {
      position: absolute;
      right: 0;
      top: 18%; }

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .testimonials-container .testimonials {
    flex-basis: 100%;
    padding: 1rem 2rem; }
    .testimonials-container .testimonials .quote {
      top: 5%;
      right: 5%; }
    .testimonials-container .testimonials .testimonial-header {
      margin: unset; }
    .testimonials-container .testimonials .testimonial-body {
      margin: unset; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .testimonials-container {
    padding: 4rem 1rem; }
    .testimonials-container .testimonials {
      flex-basis: 60%; }
      .testimonials-container .testimonials .quote {
        top: 0;
        right: 0; }
      .testimonials-container .testimonials .testimonial-header {
        margin: unset; }
      .testimonials-container .testimonials .testimonial-body {
        margin: unset; } }
